globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"3803bfe4aee60adf23a4906a371f3801a8457841"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

Sentry.init({
  dsn:
    process.env.NEXT_PUBLIC_PAGES_ROUTER === 'true'
      ? 'https://acefa0c4155042c7a28a0fae734bb32d@o866780.ingest.sentry.io/5830343'
      : 'https://490613dcab92107eb636ffff0121af01@o866780.ingest.sentry.io/4506398899306496',
  ...standardSentryConfig,
  ...extraClientConfig,
  // This MUST be here as overwrite in the client config for env to be set with next-env-vars
  // Can be removed when this application is cf pages only
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ?? 'dev',
});
